<template>
    <div class="container-fluid mt-5">
      <div class="row my-3 justify-content-center">
        <div class="col-12 col-lg-10">
          <div class="h3 text-muted d-none d-lg-block">Wann und wo findet die nächste Schulung statt?</div>
				<div class="h3 text-muted d-block d-lg-none">Wann ist was?</div>
				<div class="h1 company-color1">Unsere Terminübersicht<br></div>
				Hier findest du alle Informationen über die nächsten Schulungstermine.<br>
				Du kannst nach Kursen oder nach dem Standort filtern lassen.<br>
        Du suchst nach unseren Maßnahmen? Die findest du <router-link class="text-secondary" to="/massnahmen">hier</router-link>.<br>
				<small class="text-muted">Du vermisst etwas oder hast weitere Fragen? Kontaktiere uns gerne.</small>
        </div>
      </div>
    <fa-element client_id="fahrschule_werner" type="education_list" category="default" hideEmpty />
  </div>
</template>

<script>

export default {
  name: 'TheoryStandard',
}
</script>
